
import React from "react";

import { StaticNavbar } from "./navbar";

export const LoadingPage = () => {
    return (
        <>
            <StaticNavbar />
        </>
    )
};